import React from "react";
import { graphql } from "gatsby";
// import { getMdxProps } from "gatsby-theme-hypercore/src/templates/page";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { withStyles } from "@material-ui/core";
import Layout from "gatsby-theme-hypersite/src/layout";
import { Content } from "../components";

const DefaultPage = ({ classes, ...props }) => {
	const { mdx } = props.data;
	// const { pageContext } = props;
	// console.log(pageContext);
	return (
		<Layout {...props}>
			<Content className={classes.content}>
				<MDXRenderer {...mdx} {...props}>
					{mdx.body}
				</MDXRenderer>
			</Content>
		</Layout>
	);
};

export default withStyles(theme => ({
	root: {
		paddingTop: 0
	},
	content: {
		"& > *:first-child": {
			marginTop: 0
		}
	}
}))(DefaultPage);

export const pageQuery = graphql`
	query ($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			slug
			frontmatter {
				hero
				name
				meta {
					title
					description
					keywords
				}
				store {
					key
					value
				}
			}
		}
	}
`;
